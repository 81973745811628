// (c) ammap.com | SVG (in JSON format) map of USA: Mainland Mercator-calibrated - Low
// areas: {id:"US-AK"},{id:"US-AL"},{id:"US-AR"},{id:"US-AZ"},{id:"US-CA"},{id:"US-CO"},{id:"US-CT"},{id:"US-DC"},{id:"US-DE"},{id:"US-FL"},{id:"US-GA"},{id:"US-HI"},{id:"US-IA"},{id:"US-ID"},{id:"US-IL"},{id:"US-IN"},{id:"US-KS"},{id:"US-KY"},{id:"US-LA"},{id:"US-MA"},{id:"US-MD"},{id:"US-ME"},{id:"US-MI"},{id:"US-MN"},{id:"US-MO"},{id:"US-MS"},{id:"US-MT"},{id:"US-NC"},{id:"US-ND"},{id:"US-NE"},{id:"US-NH"},{id:"US-NJ"},{id:"US-NM"},{id:"US-NV"},{id:"US-NY"},{id:"US-OH"},{id:"US-OK"},{id:"US-OR"},{id:"US-PA"},{id:"US-RI"},{id:"US-SC"},{id:"US-SD"},{id:"US-TN"},{id:"US-TX"},{id:"US-UT"},{id:"US-VA"},{id:"US-VT"},{id:"US-WA"},{id:"US-WI"},{id:"US-WV"},{id:"US-WY"}
AmCharts.maps.usa2Low={
	"svg": {
		"defs": {
			//<amcharts:ammap projection="mercator" leftLongitude="-124.541947" topLatitude="49.390806" rightLongitude="-67.118615" bottomLatitude="14.052495"></amcharts:ammap>
			"amcharts:ammap": {
				"projection":"mercator",
				"leftLongitude":"-124.541947",
				"topLatitude":"49.390806",
				"rightLongitude":"-67.118615",
				"bottomLatitude":"25.052495"
			}
		},
		"g":{
			"path":[
				// {
				// 	"id":"US-AK",
				//	"title":"Alaska",
				// 	"d":"M517.54,701.07l2.66,5.08l1.63,-2.17l6.22,0.67l-0.22,2.56l5.63,1.88l3.75,-1.1l7.76,3.46l7.08,1.02l2.83,1.4l4.9,-1.75l5.58,3.23l4,1.49l0,0l-0.02,36.3l-0.02,46.52l3.63,0.22l3.58,2.04l2.57,3.2l3.27,4.72l3.58,-4.01l3.7,-2.35l1.95,3.75l2.48,2.93l3.37,3.18l2.3,4.98l3.76,7.72l6.26,4.21l0.1,4.1l-2.05,3.09l-2.02,-2.41l-3.24,-2.06l-1.04,-5.72l-4.75,-5.42l-1.98,-6.48l-3.53,-0.45l-5.85,-0.17l-4.31,-2.01l-7.61,-7.41l-3.52,-1.38l-6.44,-2.62l-5.1,0.63l-7.24,-3.4l-4.38,-3.2l-4.09,1.59l0.76,5.16l-2.03,0.47l-4.26,1.53l-3.24,2.44l-4.08,1.53l-0.53,-4.26l1.66,-7.26l3.91,-2.33l-1.01,-1.91l-4.69,4.23l-2.51,4.95l-5.3,5.18l2.69,3.48l-3.48,5.05l-3.96,2.9l-3.68,2.09l-0.91,3l-5.75,3.46l-1.16,3.1l-4.31,2.79l-2.53,-0.5l-3.44,1.81l-3.74,2.19l-3.06,2.13l-6.32,1.81l-0.58,-1.06l4.03,-2.98l3.6,-1.99l3.92,-3.56l4.57,-0.74l1.81,-2.71l5.1,-4.01l0.82,-1.36l2.72,-2.41l0.64,-5.25l1.87,-4.17l-4.24,2.15l-1.19,-1.22l-1.99,2.56l-2.4,-3.58l-0.99,2.54l-1.38,-3.54l-3.68,2.85l-2.26,-0.01l-0.32,-4.24l0.67,-2.65l-2.37,-2.6l-4.79,1.4l-3.11,-3.45l-2.52,-1.79l-0.02,-4.27l-2.84,-3.26l1.42,-4.47l3,-4.42l1.31,-4.14l2.98,-0.6l2.53,1.3l2.97,-3.95l2.67,0.71l2.81,-2.57l-0.68,-3.83l-2.06,-1.53l2.72,-3.31l-2.26,0.1l-3.91,1.87l-1.12,1.88l-2.9,-1.88l-5.21,0.96l-5.39,-2.05l-1.55,-3.48l-4.66,-5.13l5.17,-3.76l8.21,-4.47h3.03l-0.5,4.57l7.77,-0.35l-2.99,-5.7l-4.53,-3.57l-2.62,-4.78l-3.53,-4.16l-5.06,-3.13l2.06,-5.29l6.53,-0.33l4.65,-4.71l0.88,-5.14l3.76,-5.14l3.59,-1.25l6.98,-4.93l3.38,0.75l5.67,-6.05L517.54,701.07zM456.2,772.37l2.27,1.58l2.29,-0.85l2.98,2.18l3.66,1.1l-0.3,0.89l-2.79,1.72l-2.8,-1.77l-1.4,-1.48l-3.24,0.48l-0.88,-0.72L456.2,772.37zM478.85,802.58l-2.26,1.14l-2.41,-1.37l-2.23,-2l3.63,-1.27l2.92,0.67L478.85,802.58zM525.13,822.25l-3.68,2.57l-1.88,-1.73l-0.57,-3.18l3.34,-2.44l1.97,-1.05l2.44,0.47l1.56,2.14L525.13,822.25z"
				// },
				{
					"id":"US-AL",
					// "title":"Alabama",
					"d":"M812.63,622.5L815.08,636.62L817.31,647.78L818.66,651.26L819.49,653.25L817.93,655.2L817.4,658.71L817.91,660.79L817.68,662.74L817.42,664.54L818.02,665.94L818.48,667.23L799.24,667.29L793.85,667.92L793.7,668.75L795.85,671.4L795.35,673.55L794.67,675.04L786.3,673.84L786,657.09L787.66,639.27L789.34,624.56L788.66,622.35L800.52,622.35z"
				},
				{
					"id":"US-AR",
					// "title":"Arkansas",
					"d":"M774.56,610.54L774.46,611.5L773.5,613.14L771.85,614.26L771.48,616.17L769.99,617.69L770.1,621.03L769.03,622.1L768.85,623.01L767.16,623.87L767.13,625.52L765.87,628.58L764.78,629.22L763.11,630.77L762.11,633.11L760.05,637.05L759.84,639.72L760.93,642.67L760.47,644.88L752.5,644.5L742.2,644.87L733.09,644.86L733.63,638.48L731.45,638.44L729.62,638.59L729.13,637.85L729.4,627.93L729.59,616.79L727.72,604.51L739.19,604.68L749.62,604.71L759.57,604.72L770.32,605.43L771.1,606.88L770.04,608.13L768.98,609.38L768.4,610.54z"
				},
				{
					"id":"US-AZ",
					// "title":"Arizona",
					"d":"M591.76,599.13L591.77,663.4L573.1,663.46L551.59,655.69L537.35,650.29L538.22,648.15L539.47,648.16L540.51,645.93L538.84,644.39L538.51,642.71L538.02,640.73L539.86,638.14L540.61,632.97L543.47,630.64L541.68,628.46L540.46,626.31L539.68,624.31L539.16,622.76L538.97,621.73L539.55,619.48L539.09,617.22L538.89,614.99L538.9,612.5L538.06,610.91L538.7,609.48L540.69,609.48L542.49,610.3L543.78,610.76L544.4,608.99L544.81,608.6L544.74,599.2L559.95,599.02L578.08,599.04z"
				},
				{
					"id":"US-CA",
					// "title":"California",
					"d":"M488.45,538.31L488.44,575.34L506.23,591.52L523.14,607.06L538.97,621.73L539.16,622.76L539.68,624.31L540.46,626.31L541.68,628.46L543.47,630.64L540.61,632.97L539.86,638.14L538.02,640.73L538.51,642.71L538.84,644.39L540.51,645.93L539.47,648.16L538.22,648.15L526.25,649.32L515.53,650.18L514.39,646.07L510.39,640.27L500.33,633.52L496.53,633.1L492.44,629.53L484.98,628.66L482.57,626.82L481.42,620.54L472.27,608.93L464.42,592.61L464.75,589.86L460.59,585.93L453.3,575.87L451.99,565.97L446.97,559.27L449.03,548.99L448.7,538.22z"
				},
				{
					"id":"US-CO",
					// "title":"Colorado",
					"d":"M657.73,563.1L657.83,581.79L657.82,599.22L648.74,599.18L637.45,599.17L621.42,599.16L606.52,599.13L591.76,599.13L591.68,550.73L601.14,550.73L610.58,550.73L629.47,550.71L638.92,550.71L657.75,550.71L657.73,562.71z"
				},
				{
					"id":"US-CT",
					// "title":"Connecticut",
					"d":"M943.01,538.05L943.09,544.93L942.51,546.76L938.35,547.39L932.87,548.01L925.51,550.94L925.17,549.42L927.22,548.21L926.48,547.14L927.01,537.52L934.23,537.76z"
				},
				{
					"id":"US-DC",
					// "title":"Washington x2C  DC",
					"d":"M893.59,577.83L893.64,577.12L892.85,576.08L893.62,575.5L894.81,576.75z"
				},
				{
					"id":"US-DE",
					// "title":"Delaware",
					"d":"M909.02,565.62L907.62,566.89L907.86,569.24L909.83,575.77L912.39,581.92L906.1,581.92L905.41,566.5L906.14,565.53L906.99,564.98z"
				},
				{
					"id":"US-FL",
					// "title":"Florida",
					"d":"M851.63,670.08L852.86,678.02L856.45,686.87L860.69,694.9L860.69,699.05L864.79,709.89L865.19,719.26L863.23,728.27L859.28,730.1L854.63,728.84L853.14,724.29L849.56,721.89L844.56,712.89L839.94,705.2L839.08,700.1L840.7,693.61L838.53,687.62L832.4,681.16L827.02,677.04L816.41,681.59L814.24,678.56L805.33,673.67L794.67,675.04L795.35,673.55L795.85,671.4L793.7,668.75L793.85,667.92L799.24,667.29L818.48,667.23L819.92,670.17L829.4,670.68L844.69,672.31L845.39,674.21L846.61,673.24L846.63,669.45L847.77,669.08L849.64,669.88z"
				},
				{
					"id":"US-GA",
					// "title":"Georgia",
					"d":"M836.67,622.58L835.64,623.53L834.13,625.7L836.67,627.59L838.31,628.29L840.07,631.88L841.19,633.89L844.47,636.57L845.12,637.98L847.36,639.81L848.46,642.49L851.47,644.75L852.14,647.3L852.7,648.54L852.38,649.36L854.14,650.58L855.06,652.66L855.05,654.77L855.91,655.18L857.53,655.75L853.43,663.83L851.63,670.08L849.64,669.88L847.77,669.08L846.63,669.45L846.61,673.24L845.39,674.21L844.69,672.31L829.4,670.68L819.92,670.17L818.48,667.23L818.02,665.94L817.42,664.54L817.68,662.74L817.91,660.79L817.4,658.71L817.93,655.2L819.49,653.25L818.66,651.26L817.31,647.78L815.08,636.62L812.63,622.5L819.91,622.61L824.94,622.49z"
				},
				// {
				// 	"id":"US-HI",
				//	"title":"Hawaii",
				// 	"d":"M623.7,739.77l-1.43,1.28l-1.81,-0.44l-2.21,-1.74l0.77,-1.29l1.8,-1.09l2.78,0.05l0.58,0.86L623.7,739.77zM643.57,746.53l1.05,1.96l-2,0.51l-1.26,-0.94l-0.69,0.18l-0.28,0.14l-1.58,-0.03l-1.99,-3.49l1.84,-0.2l1.96,-1.32l1.63,3.01L643.57,746.53zM655.91,750.48l-1.81,1.29l-5.27,-0.73l0.49,-1.53L655.91,750.48zM657.46,752.06l1.52,1.56l2.24,-0.48l3.17,2.07l0.06,1.01l-1.15,0.91l-3.69,0.51l-0.87,-2.55l-1.66,-0.27l-1.01,-1.66l0.5,-0.98L657.46,752.06zM670.32,777l-1.11,1.87l-3.13,-1.37l-0.12,-4.02l-1.93,-4.74l2.79,-3.42l-0.89,-1.99l0.23,-1.2l0.52,-0.21l7.75,3.65l1.38,1.54l0.25,1.65l0.94,0.21l0.45,1.13l1.82,1.55l-3.05,2.65l-3.14,0.76L670.32,777z"
				// },
				{
					"id":"US-IA",
					// "title":"Iowa",
					"d":"M759.79,519L759.93,519.71L761.16,521.76L760.31,522.74L760.34,525.44L760.73,526.59L761.33,528.62L764.41,529.85L765.33,531.8L765.88,532.77L766.99,533.42L767.44,534.79L768.93,535.81L769.9,536.9L769.4,540.3L767.65,543.15L767.02,544.05L764.86,544.78L761.63,545.39L760.78,547.55L761.95,548.52L762.35,550.45L761.12,552.6L760.49,554.49L758.08,556.33L757.89,558.58L756.59,557.55L754.79,555.55L744.48,555.82L733.63,555.91L725.18,555.93L716.71,555.92L716.09,553.68L716.35,551.46L716.15,549.31L715.18,545.71L714.55,544.21L713.88,543.8L713.8,540.89L713.21,538.8L711.5,536.42L711.52,535.37L710.92,533.27L710.5,532.01L710.51,530.84L708.98,529.42L709.74,527.32L710.23,525.26L710.46,523.87L709.25,522.15L709.26,519L710.52,518.99L720.83,519.01L733.64,518.84L747.42,518.76z"
				},
				{
					"id":"US-ID",
					// "title":"Idaho",
					"d":"M572.52,505.87L572.86,506.11L572.85,538.19L544.71,538.3L516.47,538.21L516.61,515.16L517.43,511.45L516.67,509.74L514.9,508.86L514.92,506.75L516.25,503.76L518.28,501.14L519.63,496.85L520.9,493.4L521.85,491.66L521.35,489.62L519.77,488.52L517.54,485.92L517.62,483.51L516.76,481.44L516.49,462.23L516.44,444.17L525.71,444.15L526.47,459.04L528.96,462.76L528.95,465.27L530.7,467.49L532.88,468.8L534.45,470.95L537.08,475.2L539.51,477.19L542.35,477.32L541.31,480.35L540.4,483.93L541.19,487.41L540.09,488.28L540.19,491.73L541.87,493.06L543.73,491.46L544.69,489.57L545.84,489.94L546.97,491.79L548.05,496.02L549.72,497.68L550.32,501.16L550.89,502.12L552.81,502.45L553.97,504.37L555.64,507.79L557.38,505.97L560.47,506.45L560.72,505.15L566.05,505.97L568.21,505.55L569.55,502.93L570.57,503.29L571.49,505.14z"
				},
				{
					"id":"US-IL",
					// "title":"Illinois",
					"d":"M792.09,531.91L792.09,531.94L792.17,535.25L794.71,541.89L794.71,541.89L794.71,541.89L794.7,556.34L794.69,570.53L793.61,573.91L794.39,574.8L794.89,576.86L794.81,578.48L794.02,579.22L793.34,581.21L791.38,583.82L790.06,587.03L789.76,589.44L789.82,590.32L788.75,591.97L789.56,593.09L787.91,593.95L785.77,594.94L786.13,597.52L784.9,598.49L782.62,597.4L780.11,596.77L779.47,597.91L779.84,599.69L778.16,597.86L777.15,598.17L775.94,595.27L776.29,593.5L775.58,590.63L774.63,590.28L772.16,587.67L771.09,587.62L769.23,585.94L767.89,584.14L768.5,582.04L769.37,580.29L769.9,578.07L768.11,576.13L766.33,576.91L765.25,576.4L765.1,574.38L764.31,572.08L763.44,571.52L761.27,569.89L759.47,567.82L757.91,565.31L757.06,561.64L757.89,558.58L758.08,556.33L760.49,554.49L761.12,552.6L762.35,550.45L761.95,548.52L760.78,547.55L761.63,545.39L764.86,544.78L767.02,544.05L767.65,543.15L769.4,540.3L769.9,536.9L768.93,535.81L767.44,534.79L766.99,533.42L765.88,532.77L765.33,531.8L774.96,531.8L784.8,531.83L792.06,531.94L792.06,531.91z"
				},
				{
					"id":"US-IN",
					// "title":"Indiana",
					"d":"M820.35,542.28L820.32,553.58L820.25,565.57L820.19,574L819.65,574.57L820.42,577.04L820.01,577.93L818.42,577.95L816.95,579.01L814.72,578.58L814.51,580.89L813.19,581.77L811.95,583.83L810.61,584.2L808.54,587.75L806.63,586.73L806.03,585.27L804.39,587.65L803.35,588.97L801.32,587.57L799.14,588.71L798.43,589.86L795.53,588.05L793.5,589.35L791.07,588.43L790.98,589.75L789.76,589.44L790.06,587.03L791.38,583.82L793.34,581.21L794.02,579.22L794.81,578.48L794.89,576.86L794.39,574.8L793.61,573.91L794.69,570.53L794.7,556.34L794.71,541.89L794.71,541.89L795.57,542.74L798.78,542.67L801.33,541.29L801.33,541.29L811.48,541.35L820.35,541.29z"
				},
				{
					"id":"US-KS",
					// "title":"Kansas",
					"d":"M727.78,599.13L719.5,599.14L702.53,599.16L685.55,599.18L676.12,599.2L666.69,599.21L657.82,599.22L657.83,581.79L657.73,563.1L657.75,563.1L674.23,563.1L686.5,563.1L708.19,563.1L721.18,563.1L723.42,564.73L724.64,564.71L724.91,566.48L723.59,568.74L724.3,569.9L725.47,572.45L727.95,573.6L727.85,586.47z"
				},
				{
					"id":"US-KY",
					// "title":"Kentucky",
					"d":"M841.27,582.32L841.45,583.46L841.35,585.94L842.47,587.8L842.93,589.61L844.3,591.15L845.25,592.58L847.08,592.8L843.31,596.32L840.37,597.7L840.14,598.65L839.13,599.89L836.55,601.29L835.71,602.43L833.77,603.16L831.04,603.82L824.66,604.2L816.35,603.7L813.64,603.84L808.23,603.52L802.71,603.42L797.63,603.36L791.73,603.69L791.42,603.16L789.55,603.18L789.58,605.02L776.11,604.91L778.22,603.68L779.72,602.71L779.54,600.87L779.84,599.69L779.47,597.91L780.11,596.77L782.62,597.4L784.9,598.49L786.13,597.52L785.77,594.94L787.91,593.95L789.56,593.09L788.75,591.97L789.82,590.32L789.76,589.44L790.98,589.75L791.07,588.43L793.5,589.35L795.53,588.05L798.43,589.86L799.14,588.71L801.32,587.57L803.35,588.97L804.39,587.65L806.03,585.27L806.63,586.73L808.54,587.75L810.61,584.2L811.95,583.83L813.19,581.77L814.51,580.89L814.72,578.58L816.95,579.01L818.42,577.95L820.01,577.93L820.42,577.04L819.65,574.57L820.19,574L823.42,574.28L825.09,575.45L827.6,578.17L829.6,579.03L831.05,580L833.29,579.66L834.95,580.35L836.97,579.69L838.76,579.49L839.51,581.17z"
				},
				{
					"id":"US-LA",
					// "title":"Louisiana",
					"d":"M760.47,644.88L761.15,645.52L760.29,647.13L761.65,649.43L761.27,650.81L762.48,652.72L761.18,653.85L760.74,655.95L758.92,657.67L758.07,659.99L757.21,662.64L756.06,663.87L756.44,666.61L764.76,666.96L773.65,666.98L773.38,668.8L772.75,670.59L773.35,671.97L774.6,673.23L774.93,675.03L775.1,676.11L774.02,680.62L776.96,687.08L763.99,687.51L755.98,685.31L750.54,683.1L743.36,681.7L735.19,681.38L734.42,679.95L735.38,678.36L736.79,676.93L736.82,674.76L736.13,674.04L736.95,671.44L737.55,670.25L738.46,666.25L737.63,664.76L736.54,662.26L735.73,659.71L735.21,658L733.66,656.75L733.09,644.86L742.2,644.87L752.5,644.5z"
				},
				{
					"id":"US-MA",
					// "title":"Massachusetts",
					"d":"M952.31,527.2L952.22,533.97L955.33,540.68L959.24,540.99L958.25,536.38L961.08,539.19L960.33,542.79L953.96,544.82L949.43,544.58L949.17,542.66L947.69,541.21L946.99,537.91L943.01,538.05L934.23,537.76L927.01,537.52L929.04,528.76L936.82,528.97L948.22,529.08L949.19,527.82L951.19,526.95z"
				},
				{
					"id":"US-MD",
					// "title":"Maryland",
					"d":"M905.41,566.5L906.1,581.92L912.39,581.92L912.92,583.01L909.28,587.11L907.09,587.29L906.35,588.1L901.55,582.39L901.15,573.35L899.59,577.6L899.72,587.98L894.08,584.43L893.53,582.74L891.99,583.26L890.74,582.61L891.81,579.93L893.42,578.8L893.59,577.83L894.81,576.75L893.62,575.5L892.85,576.08L891.1,574.74L889.1,574.01L889.8,572.7L888.55,571.79L887.16,571.38L886.42,569.83L885.27,568.09L882.36,567.12L880.54,568.05L879.51,568.95L876.73,568.46L875.46,569.73L873.6,570.22L871.98,571.62L870.53,572.7L870.61,566.53L879.36,566.54L882.35,566.52L889.04,566.47L897.11,566.53z"
				},
				{
					"id":"US-ME",
					// "title":"Maine",
					"d":"M953.91,524.31L952.91,524.44L952.47,522.64L950.74,520.73L951.09,519.45L949.88,495.52L953.77,493.19L957.12,487.07L960,476.48L967.19,466.07L970.33,469.71L976.65,467.36L980.84,471.35L980.83,489.94L987,497.5L987.13,503.15L977.95,507.34L968.79,510.43L958.09,518.27z"
				},
				{
					"id":"US-MI",
					// "title":"Michigan",
					"d":"M822.84,478.6L822.84,478.6l3.15,0.42l0,0l-1.2,1.35l4.69,5.41l-8.31,1.22l-0.32,-0.06l-7.42,-1.43l-7.36,2.7l-6.41,1.21l-5.54,8.53l-0.24,-0.09l-0.01,0.02l-1.26,-1.57l0.7,-2.15l-2.08,-0.12l0.43,-2.18l0.57,-1.08l-0.83,-1.89l-2.23,-0.86l-0.52,-2.22l-1.84,-0.17l-2.67,-0.02l-5.44,-2.44l-8.25,-2.43l-0.76,-2.46l-1.48,-0.45l-0.01,-0.04l-0.58,0.28l-0.08,-0.07l0.08,0.07l7.28,-3.49l7.57,-4.47l8.32,-4.59l-3.03,7.3l5.35,1.75l6.64,5.22l8.37,-3.04l9.27,-1.2l1.98,3.83l2.88,0.55L822.84,478.6zM843.01,525.56L843.01,525.56l-0.24,0.17l-4.44,7.03l-2.07,4.45h0l-3.23,4.87l0,0l-3.56,0.11h-4.29l-4.83,0.09l-0.01,-0.99l-8.87,0.06l-10.15,-0.06v0l0,0l1.91,-1.74l3.83,-6.17l0.26,-8.44l-3.13,-7.99l0.47,-5.53l2.46,-6.45l2.35,-4.44l3.77,-3.18l0.88,4.46l1.67,-6.57l2.08,-1.36l1.32,-5.11l8.02,2.69l6.98,5.22l0.77,6.04l-0.86,6.01l-5.19,5.23l0.48,3.28l1.87,0.11l5.85,-5.73l3.49,1.31l1.7,7.54l0.47,5.28L843.01,525.56z"
				},
				{
					"id":"US-MN",
					// "title":"Minnesota",
					"d":"M775.15,458.2L775.15,458.21L765.53,462.83L758.83,468.39L752.43,474.21L752.4,476.23L749.92,476.99L750.02,484.63L749.72,484.62L747.39,486.12L745.38,487.41L744.03,489.89L746.01,492.41L745.3,495.78L745.29,499.38L745,502.3L747.75,504.84L748.88,504.96L751.93,506.84L752.99,507.73L753.65,509.14L756.03,511.39L759.21,513.33L759.52,514.41L759.54,517.54L759.79,519L747.42,518.76L733.64,518.84L720.83,519.01L710.52,518.99L710.65,506.8L709.5,494.09L707.85,493.05L706.92,490.98L707.43,489.15L709.54,487.65L709.7,485.68L709.71,483.22L709.12,481.15L708.33,478.94L707.87,476.16L707.76,473.03L707.44,472.28L707.05,468.22L707.01,466.36L706.83,464.7L706.36,461.87L705.22,458.99L704.12,456.42L703.96,453.85L703.84,451.08L704.16,449.27L704.22,447.61L703.34,445.55L703.2,444.14L722.72,444.16L722.75,438.63L725.94,438.55L727.61,446.44L730.55,448.86L737.14,449.73L746.76,452L755.91,456.38L763.55,454.54L775.15,458.2z"
				},
				{
					"id":"US-MO",
					// "title":"Missouri",
					"d":"M757.89,558.58L757.06,561.64L757.91,565.31L759.47,567.82L761.27,569.89L763.44,571.52L764.31,572.08L765.1,574.38L765.25,576.4L766.33,576.91L768.11,576.13L769.9,578.07L769.37,580.29L768.5,582.04L767.89,584.14L769.23,585.94L771.09,587.62L772.16,587.67L774.63,590.28L775.58,590.63L776.29,593.5L775.94,595.27L777.15,598.17L778.16,597.86L779.84,599.69L779.54,600.87L779.72,602.71L778.22,603.68L776.11,604.91L775.86,606.04L775.29,607.7L774.56,610.54L768.4,610.54L768.98,609.38L770.04,608.13L771.1,606.88L770.32,605.43L759.57,604.72L749.62,604.71L739.19,604.68L727.72,604.51L727.83,599.13L727.78,599.13L727.85,586.47L727.95,573.6L725.47,572.45L724.3,569.9L723.59,568.74L724.91,566.48L724.64,564.71L723.42,564.73L721.18,563.1L719.95,560.47L718.49,558.89L716.9,556.94L716.71,555.92L725.18,555.93L733.63,555.91L744.48,555.82L754.79,555.55L756.59,557.55z"
				},
				{
					"id":"US-MS",
					// "title":"Mississippi",
					"d":"M788.66,622.35L789.34,624.56L787.66,639.27L786,657.09L786.3,673.84L780.52,676.28L775.1,676.11L774.93,675.03L774.6,673.23L773.35,671.97L772.75,670.59L773.38,668.8L773.65,666.98L764.76,666.96L756.44,666.61L756.06,663.87L757.21,662.64L758.07,659.99L758.92,657.67L760.74,655.95L761.18,653.85L762.48,652.72L761.27,650.81L761.65,649.43L760.29,647.13L761.15,645.52L760.47,644.88L760.93,642.67L759.84,639.72L760.05,637.05L762.11,633.11L763.11,630.77L764.78,629.22L765.87,628.58L767.13,625.52L767.16,623.87L768.85,623.01L769.03,622.1L778.32,622.1z"
				},
				{
					"id":"US-MT",
					// "title":"Montana",
					"d":"M639.09,486.51L638.61,498.79L622.88,498.65L605.89,498.72L591.22,498.58L572.65,498.67L572.69,505.4L572.52,505.87L571.49,505.14L570.57,503.29L569.55,502.93L568.21,505.55L566.05,505.97L560.72,505.15L560.47,506.45L557.38,505.97L555.64,507.79L553.97,504.37L552.81,502.45L550.89,502.12L550.32,501.16L549.72,497.68L548.05,496.02L546.97,491.79L545.84,489.94L544.69,489.57L543.73,491.46L541.87,493.06L540.19,491.73L540.09,488.28L541.19,487.41L540.4,483.93L541.31,480.35L542.35,477.32L539.51,477.19L537.08,475.2L534.45,470.95L532.88,468.8L530.7,467.49L528.95,465.27L528.96,462.76L526.47,459.04L525.71,444.15L553.9,444.13L582.09,444.11L610.27,444.09L638.47,444.07L638.61,469.9z"
				},
				{
					"id":"US-NC",
					// "title":"North Carolina",
					"d":"M904.66,604.39L905.98,616.01L899.98,624.53L890.73,628.92L882.66,634.64L879.32,635.3L868.77,624.54L858.3,624.43L858.32,623.1L856.85,621.16L855.9,621.92L855.82,620.9L851.41,620.37L842.71,620.28L837.62,622.24L836.67,622.58L824.94,622.49L825.15,620.06L827.14,619.34L827.79,618.1L829.13,616.71L831.04,616.4L833.26,615.87L835.42,614.87L836.35,613.85L838.15,612.93L838.1,612.09L840.47,610.52L841.23,611.54L844.71,609.35L846.34,609.58L847.79,607.62L849.71,607.12L849.61,605.45L849.85,603.99L865.76,604.49L884.55,604.55L894.53,604.45z"
				},
				{
					"id":"US-ND",
					// "title":"North Dakota",
					"d":"M703.2,444.14L703.34,445.55L704.22,447.61L704.16,449.27L703.84,451.08L703.96,453.85L704.12,456.42L705.22,458.99L706.36,461.87L706.83,464.7L707.01,466.36L707.05,468.22L707.44,472.28L707.76,473.03L707.87,476.16L708.33,478.94L709.12,481.15L709.71,483.22L709.7,485.68L691.76,486.42L676.44,486.39L657.1,486.45L639.09,486.51L638.61,469.9L638.47,444.07L671.13,444.11z"
				},
				{
					"id":"US-NE",
					// "title":"Nebraska",
					"d":"M710.5,532.01L710.92,533.27L711.52,535.37L711.5,536.42L713.21,538.8L713.8,540.89L713.88,543.8L714.55,544.21L715.18,545.71L716.15,549.31L716.35,551.46L716.09,553.68L716.71,555.92L716.9,556.94L718.49,558.89L719.95,560.47L721.18,563.1L708.19,563.1L686.5,563.1L674.23,563.1L657.75,563.1L657.73,563.1L657.73,562.71L657.75,550.71L638.92,550.71L638.85,525.47L657.26,525.47L671.41,525.47L690.32,525.47L692.76,527.09L696.23,528.11L697.04,527.52L699.28,527.57L702.65,527.44L705.09,529.09L707.68,530.16L708.11,531.22L708.91,531.83z"
				},
				{
					"id":"US-NH",
					// "title":"New Hampshire",
					"d":"M953.91,524.31L952.31,527.2L951.19,526.95L949.19,527.82L948.22,529.08L936.82,528.97L936.07,527.64L936.81,525.98L937.04,522.6L937.33,521.79L937.65,518.73L938.68,516.14L939.46,515.01L940.58,511.91L940.79,509.81L941.11,508.53L942.93,507.9L945.04,506.37L945.41,504.73L944.71,502.84L945.82,499.23L945.8,499.22L946.75,495.94L949.88,495.52L951.09,519.45L950.74,520.73L952.47,522.64L952.91,524.44z"
				},
				{
					"id":"US-NJ",
					// "title":"New Jersey",
					"d":"M923.1,551.25L919.85,557.29L922.22,557.82L920.59,566.67L913.73,576.02L913.02,572.91L910.95,572.28L908.69,569.1L907.86,569.24L907.62,566.89L909.02,565.62L910.95,564.5L911.63,563.73L913.86,562.11L915.08,560.77L912.11,557.58L911.94,556.27L910.92,555.89L910.96,553.9L912.07,552.38L911.56,550.76L913.07,549.67L914.72,546.87L915.87,546.32z"
				},
				{
					"id":"US-NM",
					// "title":"New Mexico",
					"d":"M648.74,599.18L648.76,604.99L648.76,635.09L648.76,656.13L640,656.13L623.03,656.13L614.54,656.13L614.64,657.07L615.69,658.84L599.46,658.84L599.34,663.38L591.77,663.4L591.76,599.13L606.52,599.13L621.42,599.16L637.45,599.17z"
				},
				{
					"id":"US-NV",
					// "title":"Nevada",
					"d":"M544.71,538.3L544.74,599.2L544.81,608.6L544.4,608.99L543.78,610.76L542.49,610.3L540.69,609.48L538.7,609.48L538.06,610.91L538.9,612.5L538.89,614.99L539.09,617.22L539.55,619.48L538.97,621.73L523.14,607.06L506.23,591.52L488.44,575.34L488.45,538.31L516.47,538.25L516.47,538.21z"
				},
				{
					"id":"US-NY",
					// "title":"New York",
					"d":"M929.04,528.76L927.01,537.52L926.48,547.14L927.22,548.21L925.17,549.42L925.51,550.94L938.86,549.27L941.66,551.63L928.45,555.35L922.44,555.38L923.1,551.25L915.87,546.32L914.35,545.43L912.74,544.56L912.13,542.74L912.38,541.35L911.26,540.21L909.21,538.23L896.4,538.21L882.66,538.21L867.95,538.21L867.95,534.78L876.06,527.18L875.88,525.95L875.88,525.92L874.59,522.2L879.5,520.57L886.92,521.05L894.64,522.12L901.71,517.85L901.15,512.78L900.08,510.75L900.05,510.73L900.05,510.73L900.08,510.75L909.84,501.77L914.1,499.33L928.43,499.24L928.23,501.93L927.86,503.63L928.08,507.54L928.6,509.54L927.65,512.25L928.52,515.66L927.92,517.55L928.09,518.04L929.44,518.14z"
				},
				{
					"id":"US-OH",
					// "title":"Ohio",
					"d":"M860.79,555.21L859.49,555.84L859.88,557.41L859.45,560.24L858.47,563.44L857.56,566.08L857.4,567.34L854.83,570.12L853.75,570.71L852.47,571.05L851.33,570.78L849.23,572.87L848.85,575.05L848.55,576.23L847.71,576.71L847.59,575.36L846.32,575.07L844.99,577.69L844.84,580.35L843.61,582.02L841.27,582.32L839.51,581.17L838.76,579.49L836.97,579.69L834.95,580.35L833.29,579.66L831.05,580L829.6,579.03L827.6,578.17L825.09,575.45L823.42,574.28L820.19,574L820.25,565.57L820.32,553.58L820.35,542.28L825.18,542.18L829.47,542.18L833.03,542.07L833.03,542.07L833.03,542.07L838.84,544.67L843.55,545.31L850.38,543.65L855.96,540.16L860.81,538.46z"
				},
				{
					"id":"US-OK",
					// "title":"Oklahoma",
					"d":"M727.72,604.51L729.59,616.79L729.4,627.93L729.13,637.85L725.07,635.64L722.42,634.43L720.28,635.2L716.96,635.08L715,635.1L713.39,636.02L711.81,636.49L710.43,635.94L707.28,636.54L705.85,634.66L704.38,636.29L701.81,635.56L699.16,633.8L696.33,634.94L695.09,632.21L690.7,632.48L687.89,631.87L684.73,631.06L683.33,628.7L680.85,629.46L679.31,628.54L677.06,627.31L677.06,616.36L677.06,604.99L667.63,604.99L658.19,604.99L648.76,604.99L648.74,599.18L657.82,599.22L666.69,599.21L676.12,599.2L685.55,599.18L702.53,599.16L719.5,599.14L727.78,599.13L727.83,599.13z"
				},
				{
					"id":"US-OR",
					// "title":"Oregon",
					"d":"M517.54,485.92L519.77,488.52L521.35,489.62L521.85,491.66L520.9,493.4L519.63,496.85L518.28,501.14L516.25,503.76L514.92,506.75L514.9,508.86L516.67,509.74L517.43,511.45L516.61,515.16L516.47,538.21L516.47,538.25L488.45,538.31L448.7,538.22L445.67,531.1L448.53,517.3L449.19,502.52L450.87,482.28L452.83,482.1L455.71,482.16L458,483.4L460.74,484.17L462.49,486.76L462.74,489.02L463.44,490.91L465.62,491.42L467.93,491.38L471.58,489.96L473.72,489.53L477.09,490.11L478.57,490.81L480.55,490.33L482.7,489.25L486.95,489.42L490.45,487.94L492.29,486.84L494.7,487.43L498.08,486.02z"
				},
				{
					"id":"US-PA",
					// "title":"Pennsylvania",
					"d":"M915.87,546.32L914.72,546.87L913.07,549.67L911.56,550.76L912.07,552.38L910.96,553.9L910.92,555.89L911.94,556.27L912.11,557.58L915.08,560.77L913.86,562.11L911.63,563.73L910.95,564.5L909.02,565.62L906.99,564.98L906.14,565.53L905.41,566.5L897.11,566.53L889.04,566.47L882.35,566.52L879.36,566.54L870.61,566.53L860.79,566.53L860.79,555.21L860.81,538.46L860.82,538.46L867.93,534.8L867.95,534.78L867.95,538.21L882.66,538.21L896.4,538.21L909.21,538.23L911.26,540.21L912.38,541.35L912.13,542.74L912.74,544.56L914.35,545.43z"
				},
				{
					"id":"US-RI",
					// "title":"Rhode Island",
					"d":"M949.43,544.58L942.51,546.76L943.09,544.93L943.01,538.05L946.99,537.91L947.69,541.21L949.17,542.66z"
				},
				{
					"id":"US-SC",
					// "title":"South Carolina",
					"d":"M879.32,635.3L875.62,643.21L867.39,650.12L857.53,655.75L855.91,655.18L855.05,654.77L855.06,652.66L854.14,650.58L852.38,649.36L852.7,648.54L852.14,647.3L851.47,644.75L848.46,642.49L847.36,639.81L845.12,637.98L844.47,636.57L841.19,633.89L840.07,631.88L838.31,628.29L836.67,627.59L834.13,625.7L835.64,623.53L836.67,622.58L837.62,622.24L842.71,620.28L851.41,620.37L855.82,620.9L855.9,621.92L856.85,621.16L858.32,623.1L858.3,624.43L868.77,624.54z"
				},
				{
					"id":"US-SD",
					// "title":"South Dakota",
					"d":"M710.52,518.99L709.26,519L709.25,522.15L710.46,523.87L710.23,525.26L709.74,527.32L708.98,529.42L710.51,530.84L710.5,532.01L708.91,531.83L708.11,531.22L707.68,530.16L705.09,529.09L702.65,527.44L699.28,527.57L697.04,527.52L696.23,528.11L692.76,527.09L690.32,525.47L671.41,525.47L657.26,525.47L638.85,525.47L638.61,498.79L639.09,486.51L657.1,486.45L676.44,486.39L691.76,486.42L709.7,485.68L709.54,487.65L707.43,489.15L706.92,490.98L707.85,493.05L709.5,494.09L710.65,506.8z"
				},
				{
					"id":"US-TN",
					// "title":"Tennessee",
					"d":"M831.04,603.82L845.07,604.22L849.85,603.99L849.61,605.45L849.71,607.12L847.79,607.62L846.34,609.58L844.71,609.35L841.23,611.54L840.47,610.52L838.1,612.09L838.15,612.93L836.35,613.85L835.42,614.87L833.26,615.87L831.04,616.4L829.13,616.71L827.79,618.1L827.14,619.34L825.15,620.06L824.94,622.49L819.91,622.61L812.63,622.5L800.52,622.35L788.66,622.35L778.32,622.1L769.03,622.1L770.1,621.03L769.99,617.69L771.48,616.17L771.85,614.26L773.5,613.14L774.46,611.5L774.56,610.54L775.29,607.7L775.86,606.04L776.11,604.91L789.58,605.02L789.55,603.18L791.42,603.16L791.73,603.69L797.63,603.36L802.71,603.42L808.23,603.52L813.64,603.84L816.35,603.7L824.66,604.2z"
				},
				{
					"id":"US-TX",
					// "title":"Texas",
					"d":"M729.13,637.85L729.62,638.59L731.45,638.44L733.63,638.48L733.09,644.86L733.66,656.75L735.21,658L735.73,659.71L736.54,662.26L737.63,664.76L738.46,666.25L737.55,670.25L736.95,671.44L736.13,674.04L736.82,674.76L736.79,676.93L735.38,678.36L734.42,679.95L735.19,681.38L727.14,683.64L718.56,691.6L709.19,696.21L704.04,701.28L701.87,706.04L701.78,713.31L702.25,718.34L704.04,721.89L700.36,722.21L693.66,719.91L686.31,716.67L683.67,711.74L681.59,704.35L676.03,698.31L672.77,692.05L668.04,684.71L661.39,680.41L653.68,680.62L647.73,689.12L639.91,685.9L635.03,682.65L632.69,676.7L629.56,671.02L623.95,666.21L619.13,662.74L615.69,658.84L614.64,657.07L614.54,656.13L623.03,656.13L640,656.13L648.76,656.13L648.76,635.09L648.76,604.99L658.19,604.99L667.63,604.99L677.06,604.99L677.06,616.36L677.06,627.31L679.31,628.54L680.85,629.46L683.33,628.7L684.73,631.06L687.89,631.87L690.7,632.48L695.09,632.21L696.33,634.94L699.16,633.8L701.81,635.56L704.38,636.29L705.85,634.66L707.28,636.54L710.43,635.94L711.81,636.49L713.39,636.02L715,635.1L716.96,635.08L720.28,635.2L722.42,634.43L725.07,635.64z"
				},
				{
					"id":"US-UT",
					// "title":"Utah",
					"d":"M591.68,550.73L591.76,599.13L578.08,599.04L559.95,599.02L544.74,599.2L544.71,538.3L572.85,538.19L572.81,550.41z"
				},
				{
					"id":"US-VA",
					// "title":"Virginia",
					"d":"M901.94,597.1l2.71,7.29l-10.12,0.06l-9.98,0.1l-18.79,-0.06l-15.91,-0.51l-4.78,0.23l-14.02,-0.4l2.72,-0.66l1.94,-0.72l0.84,-1.14l2.58,-1.4l1,-1.24l0.23,-0.95l2.94,-1.38l3.77,-3.52l0.42,2.01l1.06,1.07l1.43,0.95l2.97,-1.42l1.13,0.83l3.52,-0.99l0.2,-1.06l1.07,0.42l1.17,-0.86l1.31,0.17l1.5,-0.91l0.2,-1.08l-0.15,-1.39l1.28,-2.06l1.82,-1.56l0.46,-1.76l1.62,-2.13l0.9,-2.61l1.25,0.93l1.4,0.86l1.35,-0.46l0.45,-1.09l0.93,-1.49l1.05,-1.71l0.68,0.5l1.4,-1.56l1.85,-1.58l1.17,-1.2l0.74,-3.23l4.82,3.29l1.06,-2.28l1.38,0.41l1.25,0.91l-0.69,1.3l1.99,0.73l1.76,1.35l0.78,1.03l-0.04,0.71l-0.17,0.97l-1.61,1.13l-1.07,2.67l1.25,0.65l1.53,-0.52l0.55,1.68l5.94,6.67l1.82,5.66L901.94,597.1zM909.28,587.11l-2.19,0.18l-0.74,0.81l-3.23,8l0.86,0.47L909.28,587.11z"
				},
				{
					"id":"US-VT",
					// "title":"Vermont",
					"d":"M936.82,528.97L929.04,528.76L929.44,518.14L928.09,518.04L927.92,517.55L928.52,515.66L927.65,512.25L928.6,509.54L928.08,507.54L927.86,503.63L928.23,501.93L928.43,499.24L945.8,499.22L945.82,499.23L944.71,502.84L945.41,504.73L945.04,506.37L942.93,507.9L941.11,508.53L940.79,509.81L940.58,511.91L939.46,515.01L938.68,516.14L937.65,518.73L937.33,521.79L937.04,522.6L936.81,525.98L936.07,527.64z"
				},
				{
					"id":"US-WA",
					// "title":"Washington",
					"d":"M516.44,444.17L516.49,462.23L516.76,481.44L517.62,483.51L517.54,485.92L498.08,486.02L494.7,487.43L492.29,486.84L490.45,487.94L486.95,489.42L482.7,489.25L480.55,490.33L478.57,490.81L477.09,490.11L473.72,489.53L471.58,489.96L467.93,491.38L465.62,491.42L463.44,490.91L462.74,489.02L462.49,486.76L460.74,484.17L458,483.4L455.71,482.16L452.83,482.1L450.87,482.28L448.29,470.9L446.73,457.49L459.87,460.04L464.05,470.94L466.38,467.29L464.87,455.81L460.4,444.08L488.42,444.14z"
				},
				{
					"id":"US-WI",
					// "title":"Wisconsin",
					"d":"M767.55,478.01L767.63,478.08L768.22,477.8L768.23,477.84L769.71,478.29L770.47,480.75L778.72,483.18L784.16,485.62L786.83,485.64L788.66,485.82L789.18,488.04L791.41,488.9L792.25,490.78L791.67,491.86L791.24,494.04L793.32,494.16L792.62,496.31L793.88,497.88L793.89,497.86L794.13,497.96L794.04,498.09L790.35,502.87L791.63,504.43L798.56,495.88L799.98,495.83L795.08,506.04L792.95,515.17L791.16,522.5L792.34,528.79L792.09,531.91L792.06,531.91L792.06,531.94L784.8,531.83L774.96,531.8L765.33,531.8L764.41,529.85L761.33,528.62L760.73,526.59L760.34,525.44L760.31,522.74L761.16,521.76L759.93,519.71L759.79,519L759.54,517.54L759.52,514.41L759.21,513.33L756.03,511.39L753.65,509.14L752.99,507.73L751.93,506.84L748.88,504.96L747.75,504.84L745,502.3L745.29,499.38L745.3,495.78L746.01,492.41L744.03,489.89L745.38,487.41L747.39,486.12L749.72,484.62L750.02,484.63L749.92,476.99L752.4,476.23L752.4,476.23L761.85,473.37z"
				},
				{
					"id":"US-WV",
					// "title":"West Virginia",
					"d":"M870.61,566.53L870.53,572.7L871.98,571.62L873.6,570.22L875.46,569.73L876.73,568.46L879.51,568.95L880.54,568.05L882.36,567.12L885.27,568.09L886.42,569.83L887.16,571.38L886.11,573.66L881.29,570.37L880.55,573.6L879.37,574.81L877.53,576.38L876.13,577.94L875.45,577.44L874.4,579.15L873.47,580.64L873.01,581.73L871.66,582.2L870.26,581.34L869.01,580.41L868.1,583.02L866.49,585.15L866.02,586.91L864.2,588.47L862.92,590.52L863.08,591.92L862.87,593L861.38,593.92L860.06,593.75L858.9,594.61L857.83,594.19L857.62,595.25L854.11,596.24L852.97,595.41L850,596.83L848.57,595.88L847.5,594.81L847.08,592.8L845.25,592.58L844.3,591.15L842.93,589.61L842.47,587.8L841.35,585.94L841.45,583.46L841.27,582.32L843.61,582.02L844.84,580.35L844.99,577.69L846.32,575.07L847.59,575.36L847.71,576.71L848.55,576.23L848.85,575.05L849.23,572.87L851.33,570.78L852.47,571.05L853.75,570.71L854.83,570.12L857.4,567.34L857.56,566.08L858.47,563.44L859.45,560.24L859.88,557.41L859.49,555.84L860.79,555.21L860.79,566.53z"
				},
				{
					"id":"US-WY",
					// "title":"Wyoming",
					"d":"M638.61,498.79L638.85,525.47L638.92,550.71L629.47,550.71L610.58,550.73L601.14,550.73L591.68,550.73L572.81,550.41L572.85,538.19L572.86,506.11L572.52,505.87L572.69,505.4L572.65,498.67L591.22,498.58L605.89,498.72L622.88,498.65z"
				}
			]
		}
	}
};